import Popup from "@/lib/Popup";
import AddStoryCategoryModal from '@/components/modals/AddStoryCategoryModal';

export const openAddStoryCategoryModal = (storyId, tempCategories) => {
    const popup = new Popup({
        component: AddStoryCategoryModal,
        props: {storyId, tempCategories},
        params: {
            transition: 'scale',
            name: 'add-story-category-modal',
            width: '100%',
            height: '100%',
            classes: 'modal-popup__overlay'
        }
    });
    
    popup.show();
};

export default {openAddStoryCategoryModal};
