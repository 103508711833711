export const RESTRICTIONS_GENDER = {
    'M': {
        name: 'Male',
        isForMen: true,
        isForWomen: false
    },
    'F': {
        name: 'Female',
        isForMen: false,
        isForWomen: true
    },
    'F&M': {
        name: 'Male & Female',
        isForMen: true,
        isForWomen: true
    }
};
